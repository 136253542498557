/*global $*/
/*eslint no-undef: "error"*/

export default class HistoryTab {
    classItem = 'history-item'
    classItemOpen = 'history-item-open'
    classButton = 'history-item__button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            console.log('work')
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
