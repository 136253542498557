/*global $*/
/*eslint no-undef: "error"*/

export default class SectionTag {
    classButton = 'section-tag'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function (e) {
            let block = $(this).data('block')
            $('html,body').stop().animate({ scrollTop: $(`#${block}`).offset().top }, 500)
            e.preventDefault()
            return false
        })
    }
}
