/*global $*/
/*eslint no-undef: "error"*/

export default class Tags {
    classButtonMore = 'tags__item--more'
    classWrapper = 'tags__wrapper'
    classItem = 'tags__item'
    classItemHidden = 'tags__item-hidden'
    classTabName = 'section-tag__name'

    constructor () {
        let self = this

        $(`.${self.classButtonMore}`).click(function () {
            let currentTo = $(this).data('to')
            let currentDo = $(this).data('do')
            let currentCount = $(this).data('count')
            let currentName = $(this).find(`.${self.classTabName}`).text()

            if (currentName === currentTo) {
                $(this).find(`.${self.classTabName}`).text(currentDo)
                $(this).closest(`.${self.classWrapper}`).find(`.${self.classItem}`).removeClass(self.classItemHidden)
            } else {
                $(this).find(`.${self.classTabName}`).text(currentTo)
                $(this).closest(`.${self.classWrapper}`).find(`.${self.classItem}`).each((key, item) => {
                    if ((key >= (currentCount)) && (!$(item).hasClass(self.classButtonMore))) {
                        $(item).addClass(self.classItemHidden)
                    }
                })
            }
        })
    }
}
