<template lang="pug">
.form
  .form__wrapper
    .form__block(v-show="!send")
      .form__fields(v-show="!send")
        .form__field
          label.input-text(:class="{'input-text-focus': focusedName}")
            span.input-text__wrapper
              span.input-text__item
                svg.input-text__icon
                  use(xlink:href="#input-text-name")
              span.input-text__item
                input.input-text__input(
                  type="text"
                  placeholder="Ваше имя *"
                  v-model="fieldName"
                  @focus="focusedName = true"
                  @blur="focusedName = false"
                )

        .form__field
          label.input-text(
            :class="{'input-text-focus': focusedPhone, 'input-text-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
          )
            span.input-text__wrapper
              span.input-text__item
                svg.input-text__icon
                  use(xlink:href="#input-text-phone")
              span.input-text__item
                input.input-text__input(
                  type="tel"
                  placeholder="Ваш телефон *"
                  v-model="fieldPhone"
                  v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
                  @focus="focusedPhone = true"
                  @blur="focusedPhone = false"
                )

        .form__field
          label.input-text(:class="{'input-text-focus': focusedEmail}")
            span.input-text__wrapper
              span.input-text__item
                svg.input-text__icon
                  use(xlink:href="#input-text-email")
              span.input-text__item
                input.input-text__input(
                  type="email"
                  placeholder="E-mail"
                  v-model="fieldEmail"
                  @focus="focusedEmail = true"
                  @blur="focusedEmail = false"
                )

        .form__field
          label.input-textarea.input-textarea-text(:class="{'input-text-focus': focusedText}")
            span.input-textarea__wrapper
              textarea.input-textarea__input(
                placeholder="Комментарий *"
                v-model="fieldText"
                @focus="focusedText = true"
                @blur="focusedText = false"
              )

      .form__sub Дополнительные услуги

      .form__checks
        .form__check
          label.input-check
            input.input-check__input(type="checkbox" value="Выезд на замер" v-model="fieldType")
            .input-check__buttons
              .input-check__button
              .input-check__value Выезд на замер

        .form__check
          label.input-check
            input.input-check__input(type="checkbox" value="Разработка дизайна" v-model="fieldType")
            .input-check__buttons
              .input-check__button
              .input-check__value Разработка дизайна

        .form__check
          label.input-check
            input.input-check__input(type="checkbox" value="Монтаж" v-model="fieldType")
            .input-check__buttons
              .input-check__button
              .input-check__value Монтаж

        .form__check
          label.input-check
            input.input-check__input(type="checkbox" value="Доставка" v-model="fieldType")
            .input-check__buttons
              .input-check__button
              .input-check__value Доставка

      .form__files
        .form-files
          .form-files__list
            label.form-files__item
              input(
                type="file"
                name="files"
                ref="files"
                multiple
                style="display: none;"
                @change="onFileChange"
              )
              svg.form-files__icon(
              )
                use(xlink:href="#icon-save")

            .form-files__item
              label.form-files__result(v-html="fileResult")
          .form-files__description(v-html="formFieldFilesText")

      .form__bottom
        .form__button
          .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
            .button__wrapper
              .button__text Оставить заявку
              .button__icon
                svg.button__icon-wrapper
                  use(xlink:href="#button-icon-2")

        .form__consent Нажимая на кнопку «Отправить», Вы даете согласие на обработку своих персональных данных и согласие с <a href="/politika-konfidenczialnosti/">политикой конфиденциальности</a>*.

    .form__success(v-show="send")
      p.p-gray.p-center Ваша заявка отправлена.<br>В ближайшее время с вами свяжется<br>наш специалист.
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      send: false,
      fieldName: '',
      fieldPhone: '',
      fieldEmail: '',
      fieldText: '',
      fieldType: [],
      focusedName: false,
      focusedPhone: false,
      focusedEmail: false,
      focusedText: false,

      fileResult: 'Прикрепить файл',
      formFieldFilesText: '(pdf, jpg, png)',
      files: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
        this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
        this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
        this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (!this.v$.$invalid) {
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Заявка"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'E-mail',
              value: this.fieldEmail
            },
            {
              title: 'Комментарий',
              value: this.fieldText
            },
            {
              title: 'Дополнительные услуги',
              value: this.fieldType.join(', ')
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          this.send = !this.send
        }, 4000)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },
      fieldText: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(300)
      }
    }
  }
}
</script>
