/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderMobile {
    classButton = 'menu-toggle__icon'
    classButtonClose = 'menu-toggle__icon-close'
    classHeaderMobile = 'header-mobile'
    classHeaderMobileActive = 'header-mobile-active'
    classHeader = 'header'
    classHeaderFixed = 'header-fixed'
    classBody = 'body'
    classBodyFixed = 'body-fixed'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let headerObj = $(`.${self.classHeader}`)
            $(this).toggleClass(self.classButtonClose)
            $(`.${self.classHeaderMobile}`).toggleClass(self.classHeaderMobileActive)
            headerObj.toggleClass(self.classHeaderFixed)
            $(`.${self.classBody}`).toggleClass(self.classBodyFixed)

            $('html, body').animate({scrollTop: 0}, 600)
        })
    }
}
