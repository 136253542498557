<template lang="pug">
.form-popup
  .form-popup__wrapper
    .h2.h2-center.h2-gray(v-if="!send")
      span.h2__text(v-html="settings.title")
    .h2.h2-center.h2-gray(v-else)
      span.h2__text Спасибо!

    .form-popup__block(v-show="!send")
      .form-popup__fields
        .form-popup__field
          label.input-text.input-text-gray(:class="{'input-text-focus': focusedName}")
            span.input-text__wrapper
              span.input-text__item
                svg.input-text__icon
                  use(xlink:href="#input-text-name")
              span.input-text__item
                input.input-text__input(
                  type="text"
                  placeholder="Ваше имя *"
                  v-model="fieldName"
                  @focus="focusedName = true"
                  @blur="focusedName = false"
                )

        .form-popup__field
          label.input-text.input-text-gray(
            :class="{'input-text-focus': focusedPhone, 'input-text-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
          )
            span.input-text__wrapper
              span.input-text__item
                svg.input-text__icon
                  use(xlink:href="#input-text-phone")
              span.input-text__item
                input.input-text__input(
                  type="tel"
                  placeholder="Ваш телефон *"
                  v-model="fieldPhone"
                  v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
                  @focus="focusedPhone = true"
                  @blur="focusedPhone = false"
                )

        .form-popup__field
          label.input-text.input-text-gray(
            :class="{'input-text-focus': focusedEmail}"
          )
            span.input-text__wrapper
              span.input-text__item
                svg.input-text__icon
                  use(xlink:href="#input-text-email")
              span.input-text__item
                input.input-text__input(
                  type="tel"
                  placeholder="Ваш e-mail"
                  v-model="fieldPEmail"
                  @focus="focusedEmail = true"
                  @blur="focusedEmail = false"
                )

        .form-popup__field
          label.input-textarea.input-textarea-gray(:class="{'input-text-focus': focusedMessage}")
            span.input-textarea__wrapper
              textarea.input-textarea__input(placeholder="Сообщение *" v-model="fieldMessage" @focus="focusedMessage = true" @blur="focusedMessage = false")

        .form-popup__field
          .form-files
            .form-files__list
              label.form-files__item
                input(
                  type="file"
                  name="files"
                  ref="files"
                  multiple
                  style="display: none;"
                  @change="onFileChange"
                )
                svg.form-files__icon(
                )
                  use(xlink:href="#icon-save")

              .form-files__item
                label.form-files__result(v-html="fileResult")
            .form-files__description(v-html="formFieldFilesText")

      .form-popup__button
        .form-popup__button-wrapper
          .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
            .button__wrapper
              .button__text Отправить
              span.button__icon
                svg.button__icon-wrapper
                  use(xlink:href="#button-icon-1")
      .form-popup__consent
        .consent
          .consent__wrapper
            input.consent__input(type="checkbox" id="for-form-popup" v-model="consent" value="consent" checked="checked")
            label.consent__label(for="for-form-popup") Даю свое согласие на обработку <a href="/politika-konfidenczialnosti/">персональных данных</a>
    .form-popup__success(v-show="send")
      p.p-gray.p-center Ваша заявка отправлена.<br>В ближайшее время с вами свяжется<br>наш специалист.
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPopup',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      send: false,
      fieldName: '',
      fieldPhone: '',
      fieldPEmail: '',
      fieldMessage: '',
      focusedName: false,
      focusedPhone: false,
      focusedEmail: false,
      focusedMessage: false,
      consent: ['consent'],

      fileResult: 'Прикрепить файл',
      formFieldFilesText: '(pdf, jpg, png)',
      files: ''
    }
  },

  computed: {
    settings () {
      return window.appReklama56.formPopup.settings
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
        this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
        this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
        this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (!this.v$.$invalid) {
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Отравить заявку" сплывающая форма'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'E-mail',
              value: this.fieldPEmail
            },
            {
              title: 'Сообщение',
              value: this.fieldMessage
            },
            {
              title: 'Адрес страницы',
              value: this.settings.pageUrl
            },
            {
              title: 'Название страницы',
              value: this.settings.pageName
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldPEmail = this.fieldMessage = ''

        setTimeout(() => {
          this.send = !this.send
        }, 4000)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },
      fieldMessage: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(300)
      },

      consent: {
        required
      }
    }
  }
}
</script>
