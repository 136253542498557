/*global $*/
/*eslint no-undef: "error"*/

export default class FormPopup {
    classButton = 'button--popup-form'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            window.appReklama56.formPopup.settings.title = $(this).data('title')
        })
    }
}
