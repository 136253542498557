/*global $*/
/*eslint no-undef: "error"*/

export default class PortfolioSection {
    classTab = 'tab-item'
    classTabActive = 'tab-item-active'
    classSlider = 'portfolio-sections__slider'
    classSliderActive = 'portfolio-sections__slider-active'

    constructor () {
        let self = this

        $(`.${self.classTab}`).click(function () {
            let block = $(this).data('block')
            $(`.${self.classTab}`).removeClass(self.classTabActive)
            $(this).addClass(self.classTabActive)

            $(`.${self.classSlider}`).removeClass(self.classSliderActive)
            $(`.${self.classSlider}[data-block="${block}"]`).addClass(self.classSliderActive)
        })
    }
}
