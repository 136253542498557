import { createApp } from 'vue'
import Maska from 'maska'
import './assets/js/css/ImportCss'
// import './assets/js/Swiper/Swiper'
import store from './store/store'
import AppForm from './components/Form/Form/AppForm'
import AppFormPopup from './components/Form/Form/AppFormPopup'
import AppFormReview from './components/Form/Form/AppFormReview'
import SwiperSlider from './assets/js/Swiper/Swiper'
import FaqItem from './assets/js/Faq/FaqItem'
import ShowHidden from './assets/js/ShowHidden/ShowHidden'
import SectionTag from './assets/js/SectionTag/SectionTag'
import HistoryTab from './assets/js/HistoryTab/HistoryTab'
import PortfolioSection from './assets/js/PortfolioSection/PortfolioSection'
import MenuMobile from './assets/js/Menu/MenuMobile'
import HeaderMobile from './assets/js/HeaderMobile/HeaderMobile'
import FormPopup from './assets/js/FormPopup/FormPopup'
import HeaderFixed from './assets/js/HeaderFixed/HeaderFixed'
import Cookie from './assets/js/Cookie/Cookie'
import Tags from './assets/js/Tags/Tags'
import ButtonTop from './assets/js/Button/Top/ButtonTop'
import Short from './assets/js/Short/Short'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

// Подключаем fancybox
require('@fancyapps/fancybox')

$(document).ready(function () {
    if ($(`#form-page`).length > 0) {
        createApp(AppForm).use(store).use(Maska).mount('#form-page')
    }
    if ($(`#form-popup`).length > 0) {
        createApp(AppFormPopup).use(store).use(Maska).mount('#form-popup')
    }
    if ($(`#form-review`).length > 0) {
        createApp(AppFormReview).use(store).use(Maska).mount('#form-review')
    }
    new FaqItem()
    new SwiperSlider()
    new ShowHidden()
    new SectionTag()
    new HistoryTab()
    new PortfolioSection()
    new MenuMobile()
    new HeaderMobile()
    new FormPopup()
    new HeaderFixed()
    new Cookie()
    new Tags()
    new ButtonTop()
    new Short()
})
