/*global $*/
/*eslint no-undef: "error"*/

export default class MenuMobile {
    classBlock = 'menu-mobile-item'
    classBlockActive = 'menu-mobile-item-active'
    classBlockSub = 'menu-mobile-item-sub'
    classItem = 'menu-mobile-item__button'

    constructor () {
        let self = this

        $(`.${self.classBlockSub} .${self.classItem}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockActive)
        })
    }
}
