<template lang="pug">
.form-review
  .form-review__wrapper
    .form-review__fields(v-show="!send")
      .form-review__field
        label.input-text(:class="{'input-text-focus': focusedName}")
          span.input-text__wrapper
            span.input-text__item
              svg.input-text__icon
                use(xlink:href="#input-text-name")
            span.input-text__item
              input.input-text__input(
                type="text"
                placeholder="Ваше имя *"
                v-model="fieldName"
                @focus="focusedName = true"
                @blur="focusedName = false"
              )
      .form-review__field
        label.input-textarea(:class="{'input-text-focus': focusedMessage}")
          span.input-textarea__wrapper
            textarea.input-textarea__input(placeholder="Ваш отзыв" v-model="fieldMessage" @focus="focusedMessage = true" @blur="focusedMessage = false")

    .form-review__button(v-show="!send")
      .form-review__button-wrapper
        .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
          .button__wrapper
            .button__text Оставить заявку
            .button__icon
              svg.button__icon-wrapper
                use(xlink:href="#button-icon-2")
    .form-review__success(v-show="send")
      p.p-gray.p-center Ваша заявка отправлена.<br>В ближайшее время с вами свяжется<br>наш специалист.
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormReview',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      send: false,
      fieldName: '',
      fieldMessage: '',
      focusedName: false,
      focusedMessage: false,
      files: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с отзывов',
            subject: 'Заявка с формы "Оставить отзыв"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Отзыв',
              value: this.fieldMessage
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldMessage = ''

        setTimeout(() => {
          this.send = !this.send
        }, 4000)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      }
    }
  }
}
</script>
