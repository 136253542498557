/*global $*/
/*eslint no-undef: "error"*/

export default class FaqItem {
    classItem = 'faq-item'
    classItemOpen = 'faq-item-open'

    constructor () {
        let self = this

        $(`.${self.classItem}`).click(function () {
            $(this).toggleClass(self.classItemOpen)
        })
    }
}
